import * as React from "react";
import { Modal, Typography, Button, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { confirmationStyle } from "../../../Person/Style/PersonModelStyle.js";
import DoneIcon from "@mui/icons-material/Done";
import { ToggleTheme } from "../../../../context/ToggleThemeProvider.js";
import { useTheme } from "../../../../context/UseThemeProvider.js";

export default function Confirmation(props) {
    const { confirmationModalOpen, setDisableFields, setConfirmationModalOpen, disableFields } = props;
    var { ThemeToggle } = ToggleTheme();
    var { currentTheme: theme } = useTheme();
    return (
        <>
            <Modal
                open={confirmationModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={confirmationStyle}
                    style={{
                        zIndex: 1,
                        minWidth: "100px",
                        maxHeight: "600px",
                        overflow: "scroll",
                        backgroundColor: (ThemeToggle === "dark" ? theme.palette.dullDark.main : "")
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                        <Typography variant="h5">
                            <Box
                                sx={{ fontWeight: 600, w: 100 }}
                                style={{
                                    color: ThemeToggle === "dark" ? "white" : "narrowWhite",
                                }}
                            >
                                Are you sure that you want to make the changes
                            </Box>
                        </Typography>
                    </Box>
                    <Box>
                        {
                            <Button
                                endIcon={<DoneIcon />}
                                variant="contained"
                                style={{
                                    height: "35px",
                                    color: "white",
                                    textTransform: "capitalize",
                                    backgroundColor: "green",
                                }}
                                onClick={() => {
                                    setDisableFields(!disableFields);
                                    setConfirmationModalOpen(false);
                                }}
                            >
                                Ja
                            </Button>
                        }
                        {
                            <Button
                                endIcon={<EditIcon />}
                                variant="contained"
                                style={{
                                    height: "35px",
                                    color: "white",
                                    marginLeft: "20px",
                                    textTransform: "capitalize",
                                    backgroundColor: theme.palette.blue.main
                                }}
                                onClick={() => {
                                    setConfirmationModalOpen(false);
                                }}
                            >
                                Nein
                            </Button>
                        }
                    </Box>
                </Box >
            </Modal >
        </>
    );
}
