import React, { useState } from "react";
import { Box, InputLabel, TextField, Typography, Select, Radio, RadioGroup, FormControlLabel, Divider } from "@mui/material";
import { ToggleTheme } from "../../../context/ToggleThemeProvider";
import { Tick } from "../../../Images/CommonLogo";
import ContractDaysAndTime from "./ContractDaysAndTime";
import { useTheme } from "../../../context/UseThemeProvider";
import CustomDatePicker from "../../../Component/CustomDatePicker";

export default function ContractComponent() {
  const [team, setTeam] = useState("");
  const [contractType, setContractType] = useState("befristet");
  const [validateData, setValidateData] = useState(true);
  var { currentTheme: theme } = useTheme();

  const [startTime, setStartTime] = useState({
    monday: new Date().setHours(8, 0),
    tuesday: new Date().setHours(8, 0),
    wednesday: new Date().setHours(8, 0),
    thursday: new Date().setHours(8, 0),
    friday: new Date().setHours(8, 0),
    saturday: new Date().setHours(8, 0),
    sunday: new Date().setHours(8, 0),
  });

  const [endTime, setEndTime] = useState({
    monday: new Date().setHours(17, 0),
    tuesday: new Date().setHours(17, 0),
    wednesday: new Date().setHours(17, 0),
    thursday: new Date().setHours(17, 0),
    friday: new Date().setHours(17, 0),
    saturday: new Date().setHours(17, 0),
    sunday: new Date().setHours(17, 0),
  });

  const [editable, setEditable] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: true,
    sunday: true,
  });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (event) => {
    setTeam(event.target.value);
  };

  var { ThemeToggle } = ToggleTheme();
  var textColor = ThemeToggle === "dark" ? "white" : "black";
  var radioLabelColor = ThemeToggle === "dark" ? "white" : "";

  return (
    <>
      <Box sx={{ mt: "4px", ml: "10px" }}>
        <Typography variant="h4" gutterBottom>
          Vertrag Hinzufügen
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr" },
            gap: 2,
          }}
        >
          <Box>
            <Box className="flex align-center items-center">
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  mr: "19px",
                  color: textColor,
                }}
              >
                Vertragsnummer
              </InputLabel>
              <TextField
                size="small"
                id="standard-basic"
                autoComplete="off"
                sx={{ boxShadow: 1, width: "200px" }}
              />
            </Box>
            <Box sx={{ display: "flex", mt: "10px", color: "black" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: textColor,
                  mt: "8px",
                }}
              >
                Team
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={team}
                label="Team"
                onChange={handleChange}
                sx={{
                  height: "40px",
                  width: "200px",
                  ml: "100px",
                  boxShadow: 1,
                }}
              >
                {/* Integrate Team Here */}
                {/* <MenuItem value={0}>None</MenuItem>  */}
              </Select>
            </Box>
            <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  mr: "29px",
                  color: textColor,
                }}
              >
                Vertragsbeginn
              </InputLabel>
              <CustomDatePicker />
            </Box>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="befristet"
              name="radio-buttons-group"
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: "10px",
              }}
              onChange={(e, val) => console.log(setContractType(val))}
            >
              <FormControlLabel
                value="befristet"
                control={
                  <Radio
                    style={{
                      color: radioLabelColor,
                    }}
                  />
                }
                label="befristet"
              />
              <FormControlLabel
                value="unbefristet"
                control={
                  <Radio
                    style={{
                      color: radioLabelColor,
                    }}
                  />
                }
                label="unbefristet"
              />
            </RadioGroup>
            {contractType === "befristet" && (
              <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    mr: "115px",
                    color: textColor,
                  }}
                >
                  bis
                </InputLabel>
                <CustomDatePicker />
              </Box>
            )}
            <Divider sx={{ mt: "24px", mb: "10px", width: "350px" }} />
          </Box>
        </Box>

        <ContractDaysAndTime label={label} setEditable={setEditable} editable={editable} ThemeToggle={ThemeToggle} startTime={startTime} endTime={endTime} setStartTime={setStartTime} setValidateData={setValidateData} setEndTime={setEndTime} />

        <Box sx={{ display: "flex", mb: "100px", mt: "20px" }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '140px',
            height: '40px',
            backgroundColor: theme.palette.dullGray.main,
            padding: '8px 32px',
            borderRadius: '12px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}>
            <Typography
              className={
                "flex font-bold " +
                (ThemeToggle === "dark" ? "text-browndark" : "")
              }>
              Abbrechen
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '140px',
              height: '40px',
              paddingY: 1,
              paddingX: 8,
              marginLeft: 2,
              borderRadius: '12px',
              backgroundColor: validateData ? theme.palette.green.main : theme.palette.narrowDull.main,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Typography className="flex font-bold text-white">Fertig</Typography>
            <Box component="span" className="ml-2 ">
              <Tick />
            </Box>
          </Box>
        </Box>
      </Box >
    </>
  );
}
