const SideStyles = {
  mainDiv:
    "hidden md:block md:visible relative w-[50%] h-[100vh] transition ease-in-out delay-150",
  nestedDiv1:
    "w-[100%] h-[100%] bg-signIn-sidebar bg-cover bg-left bg-no-repeat bg-[#000000] ",
  nestedDiv2: "absolute mx-20 top-[10px]",
  gladPara: "filter-none font-[300] ml-[2px] text-[22px] pt-[10%] ",
  joinPara: "filter-none font-[800] text-[40px] mt-[50%] ",
  welcomePara:
    "ml-[2px] flex justify-center w-[130px] font-[300] bg-transparent mt-8 font-semibold py-2 px-4 border border-[#ffffff] ",
};

export default SideStyles;
