export const jsonToCsv = (jsonData) => {
    if (!jsonData || jsonData.length === 0) {
        return '';
    }

    const keys = Object.keys(jsonData[0]);
    const csvRows = [
        keys.join(','),
        ...jsonData.map(row => keys.map(key => row[key]).join(','))
    ];

    return csvRows.join('\n');
};