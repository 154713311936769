import * as React from "react";
import Logo from "../../../Images/Logo.jsx";
import SideStyles from "../Styles/SignIn.js";
import { useLocation } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import PasswordChecklist, { validatePasswordChecklist } from 'mui-password-checklist';
import Brightness4Icon from "@mui/icons-material/WbSunny";
import { useTheme } from "../../../context/UseThemeProvider.js";
import { ToggleTheme } from "../../../context/ToggleThemeProvider.js";
import { passwordReset, confirmResetPassword } from "../../../Api/AuthenticationApi.js"
import checkFieldValidations from '../../../utils/IsNull.js'
import { Button, ThemeProvider, CssBaseline, TextField, Alert, Collapse, Divider, Link, Box, Typography, Container } from "@mui/material";
import Copyright from "../../../Component/Copyright.tsx";
import { useUser } from "../../../context/UserProvider.js";

export default function ForgetPassword() {
  var { ThemeToggle, setThemeToggle } = ToggleTheme();
  var { currentTheme: theme } = useTheme();

  const location = useLocation();
  const { setUser } = useUser();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const resetPassword = token ? false : true;
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [loginAttemptFailed, setLoginAttemptFailed] = React.useState(false);
  const [checkEmptyFields, setCheckEmptyFields] = React.useState(false);
  const [checkSuccessField, setCheckSuccessField] = React.useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = React.useState(false);
  const [confirmPasswordDisabled, setConfirmPasswordDisabled] = React.useState(true);
  const [showError, setShowError] = React.useState(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  React.useEffect(() => {
    localStorage.removeItem("user");
    localStorage.clear();
    setUser(null);
  }, []);


  function checkPasswordValidation(props: String) {
    const { validationMessages } = validatePasswordChecklist(props + "")
    if (validationMessages[0]?.passed && validationMessages[1]?.passed && validationMessages[2]?.passed && validationMessages[3]?.passed && validationMessages[4]?.passed) {
      return true;
    }
    else {
      return false;
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");
    setCheckEmptyFields(false);
    setPasswordNotMatch(false);
    setLoginAttemptFailed(false);
    if (resetPassword && email === "") {
      setCheckEmptyFields(true);
    }
    else if (!resetPassword && ((checkFieldValidations(password)) || (checkFieldValidations(confirmPassword)))) {
      setCheckEmptyFields(true);
    }
    else if (!resetPassword && (checkFieldValidations(password) || password !== confirmPassword)) {
      setPasswordNotMatch(true);
    }
    else {
      try {
        const response = resetPassword ? await passwordReset({
          'identifier': email,
        }) : await confirmResetPassword({
          'password': password,
          'token': token
        });
        if (response.status === 200) {
          setLoginAttemptFailed(false);
          setCheckEmptyFields(false);
          setCheckSuccessField(true);
          setTimeout(() => {
            setFormData({ email: "", password: "", confirmPassword: "" })
            setCheckSuccessField(false);
          }, 2000)

        }
      } catch (error) {
        if (error?.response?.data?.message) {
          setShowError(error.response.data.message);
        } else {
          setShowError(error?.message);
        }
      }
    }
  };

  React.useEffect(() => {
    if (checkPasswordValidation(formData.password)) {
      setConfirmPasswordDisabled(false);
    }
    else {
      setConfirmPasswordDisabled(true);
    }
  }, [formData.password])

  React.useEffect(() => {
    localStorage.setItem("ThemeColor", ThemeToggle);
  }, [ThemeToggle]);

  return (
    <Box className="block md:flex">
      <Box className={SideStyles.mainDiv}>
        <Box
          className={
            SideStyles.nestedDiv1 +
            (ThemeToggle === "dark" ? "brightness-[0.3]" : "brightness-[0.5]")
          }
        />
        <Box className={SideStyles.nestedDiv2}>
          <Brightness4Icon
            className={
              ThemeToggle === "dark" ? "text-dullwhite" : "text-darkYellow"
            }
            onClick={() => {
              setThemeToggle(ThemeToggle === "dark" ? "white" : "dark");
            }}
          />
          <Typography
            variant="h3" component="h3"
            sx={{ mt: "50%", color: ((ThemeToggle === "dark" ? theme.palette.narrowWhite.main : "white")) }}
          >
            Herzlich Willkommen!
          </Typography>
          <Typography
            variant="h6" component="h5"
            sx={{ pt: "10%", ml: "2px", color: (ThemeToggle === "dark" ? theme.palette.narrowWhite.main : "white") }}
            className={
              SideStyles.gladPara
            }
          >
            Schön dich wieder zu sehen.
          </Typography>
        </Box>
      </Box>
      <Box className="md:pl-[20px] md:w-[60%] 2xl:pt-[69px] ">
        <Brightness4Icon
          className={
            "md:invisible ml-[10px] mt-[8px] " +
            (ThemeToggle === "dark" ? "text-dullwhite" : "text-darkYellow")
          }
          onClick={() => {
            setThemeToggle(ThemeToggle === "dark" ? "white" : "dark");
          }}
        />
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Logo />

                <Divider
                  orientation="vertical"
                  variant="middle"
                  aria-hidden="true"
                  flexItem
                  sx={{ m: 2 }}
                />
                <Typography variant="h5">
                  <Box
                    sx={{ fontWeight: 500 }}
                    style={{
                      color: ThemeToggle === "dark" ? theme.palette.narrowWhite.main : "black",
                    }}
                  >
                    Mitarbeiter Online
                  </Box>
                </Typography>
              </Box>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {resetPassword && (
                  <TextField
                    margin="normal"
                    required
                    id="email"
                    label="Username or E-Mail Adresse"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="off"
                    variant={ThemeToggle === "dark" ? "filled" : "outlined"}
                    InputLabelProps={{
                      style: { color: "dullBlack" },
                    }}
                    className="w-[350px] md:w-[400px]"
                  />
                )}
                {!resetPassword && (
                  <>
                    <Box>
                      <PasswordChecklist
                        margin="normal"
                        required
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        variant={ThemeToggle === "dark" ? "filled" : "outlined"}
                        InputLabelProps={{
                          style: { color: "dullBlack" },
                        }}
                        className="w-[350px] md:w-[400px]"

                      />
                    </Box>
                    <Box>
                      <TextField
                        margin="normal"
                        required
                        name="confirmPassword"
                        label="Confirm Passwort"
                        type="password"
                        id="confirmPassword"
                        autoComplete="current-password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        disabled={confirmPasswordDisabled}
                        variant={ThemeToggle === "dark" ? "filled" : "outlined"}
                        InputLabelProps={{
                          style: { color: "dullBlack" },
                        }}
                        className="w-[350px] md:w-[400px]"
                      />
                    </Box>
                  </>
                )}
                <Collapse in={loginAttemptFailed}>
                  <Alert severity="error" variant="filled">
                    {showError}
                  </Alert>
                </Collapse>
                <Collapse in={checkEmptyFields}>
                  <Alert severity="warning" variant="filled">
                    Alle Felder sind Pflichtfelder
                  </Alert>
                </Collapse>
                <Collapse in={passwordNotMatch}>
                  <Alert severity="warning" variant="filled">
                    Das Passwort stimmt nicht überein.
                  </Alert>
                </Collapse>
                <Collapse in={checkSuccessField}>
                  <Alert severity="success" variant="filled">
                    Dein Passwort wurde erfolgreich geändert.
                  </Alert>
                </Collapse>

                <Button
                  color={resetPassword || (!resetPassword && formData.password !== "" && formData.password === formData.confirmPassword) ? "primary" : "secondary"}
                  endIcon={<SendIcon />}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{ height: "50px" }}
                >
                  {!resetPassword && "Confirm"}
                  {resetPassword && "Reset Password"}
                </Button>
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4, ml: -4 }} />
          </Container>
        </ThemeProvider>
      </Box>
    </Box>
  );
}
