const SideStyle = {
  mainDiv:
    " pt-[10px] w-auto md:w-auto h-[100%] lg:pt-[30px] flex flex-col align-center",
  Input1:
    "w-[151px] h-[50px] mt-[8px] bg-[#E0DCDC] ml-6 text-black pl-5 placeholder:ml-[6%] placeholder:text-browndark outline-none border rounded-lg",
  Input2:
    "w-[80%] pl-5 bg-white text-black mt-[4%] mb-[4%] placeholder:ml-[6%] placeholder:text-browndark outline-none mt-[10%] border h-[41.47px] rounded-lg",
  btn1: "ml-[10px]  flex justify-center w-auto lg:w-auto h-[40px] text-white font-bold py-2 px-8 rounded-lg",
  btn2: "bg-textBox ml-[10px] flex justify-center w-auto lg:w-auto h-[40px] text-browndark py-2 px-8 rounded-lg",
  btn3: " flex justify-center w-[140px] h-[40px] text-browndark py-2 px-8 ml-2 rounded-lg",
  div2: "flex justify-start font-[800] w-auto ",
  div3: "flex justify-start pb-[15px] mt-4 w-auto lg:pb-[10px]",
  collapse: "pb-2 lg:pb-4 rounded-lg w-[79%] ml-[39px]",
  label1: "mt-4 font-thin text-[20px] ml-[10px] w-[150px] ",
  p: "flex font-bold text-white",
  p1: "mt-2 text-[18px] pl-2 w-[100px] ",
};

export default SideStyle;
