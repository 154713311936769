import React from "react";
import { ToggleTheme } from "../context/ToggleThemeProvider";

function Calender() {
  var { ThemeToggle } = ToggleTheme();
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 43 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.945 6.12623H34.1812V2.59872H30.6537V6.12623H13.0162V2.59872H9.48868V6.12623H7.72492C5.7848 6.12623 4.19742 7.71361 4.19742 9.65374V37.8738C4.19742 39.8139 5.7848 41.4013 7.72492 41.4013H35.945C37.8851 41.4013 39.4725 39.8139 39.4725 37.8738V9.65374C39.4725 7.71361 37.8851 6.12623 35.945 6.12623ZM35.945 37.8738H7.72492V14.945H35.945V37.8738Z"
        fill={ThemeToggle === "dark" ? "#ffffff" : "#000000"}
      />
    </svg>
  );
}

export default Calender;
