const MenuItems = Object.freeze({
    HAUPTMENU: "Hauptmenü",
    PERSONEN: "Personen",
    AUSWERTEN: "Auswerten",
    VERTRAG: "Verträge",
    DETAIL1: "Detail1",
    DETAIL2: "Detail2",
    Gruppen: "Gruppen",
    Zeiten: "Zeiten",
    Bearbeiten: "Bearbeiten",
    USER: "user",
    AUSLOGGEN: "Ausloggen"
});

export default MenuItems;