import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Divider, InputLabel, TextField } from "@mui/material";
import { useTheme } from "../../../context/UseThemeProvider";
import CustomTimer from "../../../Component/CustomTimer";

export default function ContractDaysAndTime({ label, setEditable, editable, ThemeToggle, startTime, endTime, setStartTime, setEndTime, setValidateData }) {

    var { currentTheme: theme } = useTheme();

    const styleTimepicker = (editable) => ({
        height: "56px",
        borderColor: "black",
        borderRadius: "10px",
        backgroundColor: editable ? theme.palette.dullGray.main : "white",
        input: {
            color: editable ? theme.palette.secondary.main : "black",
        },
        svg: { color: editable ? theme.palette.secondary.main : "black" },
    });

    function extractTimeFromTimestamp(timestamp) {
        const tempDate = new Date(timestamp);
        var formattedData = `${tempDate.getFullYear() +
            "-" +
            (tempDate.getMonth() + 1) +
            "-" +
            tempDate.getDate() +
            " " +
            tempDate.getHours().toString().padStart(2, "0") +
            ":" +
            tempDate.getMinutes().toString().padStart(2, "0")
            }`;
        return formattedData;
    }

    function checkWorkingHours(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        setValidateData(startDate < endDate);
    }

    const daysOfWeek = [
        { name: 'monday', label: 'Mo' },
        { name: 'tuesday', label: 'Di' },
        { name: 'wednesday', label: 'Mi' },
        { name: 'thursday', label: 'Do' },
        { name: 'friday', label: 'Fr' },
        { name: 'saturday', label: 'Sa' },
        { name: 'sunday', label: 'So' },
    ];


    return (
        <>
            <Box sx={{ mt: "18px" }}>
                <Typography variant="h5" gutterBottom>
                    Arbeitszeit
                </Typography>
                <>
                    {daysOfWeek.map(day => (
                        <CustomTimer
                            key={day.name}
                            day={day}
                            label={{ inputProps: { 'aria-label': 'controlled' } }}
                            startTime={startTime}
                            endTime={endTime}
                            editable={editable}
                            setEditable={setEditable}
                            setStartTime={setStartTime}
                            setEndTime={setEndTime}
                            checkWorkingHours={checkWorkingHours}
                            extractTimeFromTimestamp={extractTimeFromTimestamp}
                            styleTimepicker={styleTimepicker}
                            ThemeToggle={ThemeToggle}
                        />
                    ))}
                </>
                <Divider sx={{ mt: "24px", mb: "10px", width: "350px" }} />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mt: "16px",
                        mb: "10px",
                    }}
                >
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            mr: "19px",
                            color: ThemeToggle === "dark" ? "white" : "black",
                        }}
                    >
                        Urlaubsanspruch
                    </InputLabel>
                    <TextField
                        placeholder="29"
                        size="small"
                        type="Number"
                        id="outlined-number"
                        autoComplete="off"
                        validators={['required']}
                        inputProps={{ min: 0 }}
                        sx={{
                            boxShadow: 1,
                            width: "140px",
                            placeholder: { color: "white" },
                        }}
                    />
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            ml: "5px",
                            color: ThemeToggle === "dark" ? "white" : "black",
                        }}
                    >
                        Tage
                    </InputLabel>
                </Box>
            </Box>
        </>
    );
}

