import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import App from "./Pages/App.tsx";
import { UserProvider } from "./context/UserProvider.js";
import theme from "./Themes/darkTheme.js";
import { BrowserRouter } from "react-router-dom";
import { ToggleThemeProvider } from "./context/ToggleThemeProvider.js";
import { UseThemeProvider } from "./context/UseThemeProvider.js";
import "./index.css";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(

  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <ToggleThemeProvider>
      <UseThemeProvider>
        <UserProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <App />
          </BrowserRouter>
        </UserProvider>
      </UseThemeProvider>
    </ToggleThemeProvider>
  </ThemeProvider>
);
