import * as React from "react";
import PersononeModel from "./AddPerson";
import BoxStyle from "../Style/PersonStyle";
import PersonenTable from "./PersonTable";
import Style from "../Style/UserView";
import TokenizedSearchBar from "../../../Component/TokenizeSearchBar";
import MenuItems from "../../../utils/MenuItems";
import { GetAllPerson } from "../../../Api/PersonApi";

export default function UserView(props) {
  const { currentComponent, setCurrentComponent, setUserVertragName } = props;
  var [searchValue, setSearchValue] = React.useState([]);
  const [searchBtn, setSearchBtn] = React.useState(false);
  const [filtername, setFiltername] = React.useState(false);
  const [filteremail, setFilteremail] = React.useState(false);
  const [originalData, setOriginalData] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const openmenu = Boolean(anchorEl);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(true);
  const [menuData, setMenuData] = React.useState(null);
  const [employeeData, setEmployeeData] = React.useState([]);

  const filteredItems = employeeData.filter((item) => {
    if (searchValue && searchValue.length > 0) {
      const searchTokens = searchValue?.map(token => token?.toString()?.toLowerCase());

      const matchesAllTokens = searchTokens.every(token =>
        item.name.toLowerCase().includes(token) ||
        item.email.toLowerCase().includes(token)
      );

      return matchesAllTokens;
    }
    return true;
  });

  const transformData = (data) => {
    return Object.values(data).map(user => ({
      id: user.employeeID,
      vorname: user.firstName,
      nachname: user.lastName,
      username: user.username,
      email: user.email,
      role: user.role,
    }));
  };

  function getAllUser() {
    var userRecord1 = GetAllPerson();
    userRecord1
      .then((data) => {
        if (data) {
          const transformedData = transformData(data)
          setEmployeeData(transformedData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  React.useState(() => {
    getAllUser();
  }, [])

  React.useEffect(() => {
    if (originalData) {
      setSearchValue([])
      setOriginalData(false);
    }
  }, [originalData]);

  if (filtername || filteremail) {
    var nameA = "";
    var nameB = "";
    filteredItems &&
      filteredItems.sort((a, b) => {
        if (filtername) {
          nameA = a.name.toUpperCase();
          nameB = b.name.toUpperCase();
        } else {
          nameA = a.email.toUpperCase();
          nameB = b.email.toUpperCase();
        }
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  const handleClosemenu = (operation) => {
    setUserVertragName(menuData.username)
    if (operation === MenuItems.VERTRAG) {
      setCurrentComponent(1) //opening vertrag screen
    }
    setAnchorEl(null);
  };

  const handleClick = (event, data) => {
    setMenuData(data);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>

      <div className={Style.mainDiv}>
        <div className={Style.div1}>
          <div className={Style.div2}>
            <button
              className={BoxStyle.AddBtn}
              onClick={() => {
                setOpen(true);
              }}
            >
              Add User
            </button>
            <div className={Style.div3 + " z-50"}>
              <TokenizedSearchBar searchValue={searchValue} setSearchValue={setSearchValue} searchBtn={searchBtn} setSearchBtn={setSearchBtn} setFiltername={setFiltername} setOriginalData={setOriginalData} />
            </div>
          </div>
        </div>

        <PersonenTable
          setFiltername={setFiltername}
          filtername={filtername}
          setFilteremail={setFilteremail}
          filteremail={filteremail}
          filteredItems={filteredItems}
          openmenu={openmenu}
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClosemenu={handleClosemenu}
        />
      </div>

      <PersononeModel
        open={open}
        handleOpen={handleOpen}
        setOpen={setOpen}
        handleClose={handleClose}
        getAllUser={getAllUser}
      />
    </>
  );
}
