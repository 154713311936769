import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const handleTokenExpiration = () => {
  // Clear JWT token from localStorage
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
  // Redirect user to the login page (replace '/login' with your actual login route)
  window.location.href = "/";
};

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Token expired or invalid
      handleTokenExpiration();
    }
    return Promise.reject(error);
  }
);


const getAuthHeaders = () => {
  const jwtToken = localStorage.getItem("jwt");
  return {
    headers: {
      Authorization: jwtToken ? `Bearer ${jwtToken}` : "", // Use token if present, otherwise empty string
    },
  };
};

const logoutUser = async () => {
  const response = await api.post(
    `${process.env.REACT_APP_API_URL}/logout`,
    {
      ...getAuthHeaders()
    }
  );
  return response.data;
}

export {
  api,
  getAuthHeaders,
  logoutUser
};