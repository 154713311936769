import React from "react";
import { ToggleTheme } from "../context/ToggleThemeProvider";

function AddLogo() {
  var { ThemeToggle } = ToggleTheme();

  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6976 10.1353H18.8435V15.8435H13.1353V18.6976H18.8435V24.4058H21.6976V18.6976H27.4058V15.8435H21.6976V10.1353ZM20.2705 3C12.3932 3 6 9.39319 6 17.2705C6 25.1478 12.3932 31.541 20.2705 31.541C28.1478 31.541 34.541 25.1478 34.541 17.2705C34.541 9.39319 28.1478 3 20.2705 3ZM20.2705 28.6869C13.9772 28.6869 8.8541 23.5638 8.8541 17.2705C8.8541 10.9772 13.9772 5.8541 20.2705 5.8541C26.5638 5.8541 31.6869 10.9772 31.6869 17.2705C31.6869 23.5638 26.5638 28.6869 20.2705 28.6869Z"
        fill={ThemeToggle === "dark" ? "#ffffff" : "#000000"}
      />
    </svg>
  );
}

function EyeLogo() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.5C2.68555 0.5 0 3.18555 0 6.5C0 9.81445 2.68555 12.5 6 12.5C9.31445 12.5 12 9.81445 12 6.5C12 3.18555 9.31445 0.5 6 0.5ZM6 1.5C8.76172 1.5 11 3.73828 11 6.5C11 9.26172 8.76172 11.5 6 11.5C3.23828 11.5 1 9.26172 1 6.5C1 3.73828 3.23828 1.5 6 1.5ZM6 3.40625C5.9082 3.40625 5.83203 3.4043 5.75 3.42188C5.66797 3.43945 5.5918 3.48438 5.53125 3.53125C5.4707 3.57812 5.42578 3.64258 5.39062 3.71875C5.35547 3.79492 5.34375 3.88477 5.34375 4C5.34375 4.11328 5.35547 4.20312 5.39062 4.28125C5.42578 4.35938 5.4707 4.42188 5.53125 4.46875C5.5918 4.51562 5.66797 4.54297 5.75 4.5625C5.83203 4.58203 5.9082 4.59375 6 4.59375C6.08984 4.59375 6.18555 4.58203 6.26562 4.5625C6.3457 4.54297 6.4082 4.51562 6.46875 4.46875C6.5293 4.42188 6.57422 4.35938 6.60938 4.28125C6.64453 4.20508 6.67188 4.11328 6.67188 4C6.67188 3.88477 6.64453 3.79492 6.60938 3.71875C6.57422 3.64258 6.5293 3.57812 6.46875 3.53125C6.4082 3.48438 6.3457 3.43945 6.26562 3.42188C6.18555 3.4043 6.08984 3.40625 6 3.40625ZM5.39062 5.07812V9.5625H6.60938V5.07812H5.39062Z"
        fill="#5E5E5E"
      />
    </svg>
  );
}

function LeftArrow({ height = 100 }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.375 31.29L7.585 17.5L21.375 3.71L17.665 -3.24339e-07L0.165001 17.5L17.665 35L21.375 31.29Z"
        fill="#E0DCDC"
      />
    </svg>
  );
}

function RightArrow({ height = 100 }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.457764 4.12504L14.2478 17.915L0.457764 31.705L4.16776 35.415L21.6678 17.915L4.16776 0.415039L0.457764 4.12504Z"
        fill="#E0DCDC"
      />
    </svg>
  );
}

function Downarrow() {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiTableSortLabel-icon MuiTableSortLabel-iconDirectionDesc css-s6n6v6"
      focusable="false"
      fill="#333333"
      height={20}
      width={20}
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="ArrowDownwardIcon"
    >
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
    </svg>
  );
}

function Filter() {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
        stroke="#808080"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75 12L10.58 14.83L16.25 9.17004"
        stroke="#808080"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function SubmitLogo() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0104762 25L22 12.5L0.0104762 0L0 9.72222L15.7143 12.5L0 15.2778L0.0104762 25Z"
        fill="white"
      />
    </svg>
  );
}

function Tick() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 18.9674L2.95247 11.6699L0.467468 14.1374L10.25 23.9199L31.25 2.91992L28.7825 0.452423L10.25 18.9674Z"
        fill="white"
      />
    </svg>
  );
}

function Uparrow() {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
      focusable="false"
      height={20}
      width={20}
      fill="#333333"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="ArrowUpwardIcon"
    >
      <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path>
    </svg>
  );
}

export {
  SubmitLogo,
  AddLogo,
  EyeLogo,
  LeftArrow,
  RightArrow,
  Downarrow,
  Uparrow,
  Filter,
  Tick,
};
