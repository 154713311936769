const Style = {
    table: "table-auto w-[600px] md:w-[100%] block overflow-scroll ",
    th: "pr-[50px] pl-[10px]  lg:pl-[1%] py-2 text-[#000000] cursor-pointer",
    tag: " md:pl-8 w-[100%] md:w-auto text-nowrap",
    td: "px-6 py-1 text-[#000000] font-medium",
    noRecord: 'ml-[30%] text-2xl mt-2 md:text-3xl',

};

export default Style;
