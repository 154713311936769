import React, { createContext, useContext } from "react";

// Create a context for storing toggle data
const ToggleThemeContext = createContext();

// Create a provider component to provide current toggle data to child components using local storage
export const ToggleThemeProvider = ({ children }) => {
  var ThemeColor = localStorage.getItem("ThemeColor");
  const [ThemeToggle, setThemeToggle] = React.useState(ThemeColor || "white");

  return (
    <ToggleThemeContext.Provider value={{ ThemeToggle, setThemeToggle }}>
      {children}
    </ToggleThemeContext.Provider>
  );
};

// Custom hook to check toggle data
export const ToggleTheme = () => useContext(ToggleThemeContext);
