import React from "react";
import { ToggleTheme } from "../context/ToggleThemeProvider";

function WorkingHours() {
  var { ThemeToggle } = ToggleTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8402 7.5438H19.0722V5.15978C19.0722 3.83665 18.0113 2.77576 16.6882 2.77576H11.9201C10.597 2.77576 9.5361 3.83665 9.5361 5.15978V7.5438H4.76806C3.44492 7.5438 2.39595 8.60469 2.39595 9.92783L2.38403 23.04C2.38403 24.3631 3.44492 25.424 4.76806 25.424H23.8402C25.1634 25.424 26.2243 24.3631 26.2243 23.04V9.92783C26.2243 8.60469 25.1634 7.5438 23.8402 7.5438ZM16.6882 7.5438H11.9201V5.15978H16.6882V7.5438Z"
        fill={ThemeToggle === "dark" ? "#ffffff" : "#000000"}
      />
    </svg>
  );
}

export default WorkingHours;
