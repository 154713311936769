import * as React from "react";
import { Modal, Typography, Box, Button, TextField, Alert, Collapse, Select, MenuItem } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { editStyle } from "../Style/PersonModelStyle.js";
import { ToggleTheme } from "../../../context/ToggleThemeProvider.js";
import CloseIcon from "@mui/icons-material/Close";
import { AddPerson } from "../../../Api/PersonApi.js";

function AddPersonModel({ open, setOpen, handleClose, getAllUser }) {
  var { ThemeToggle } = ToggleTheme();
  const [role, setRole] = React.useState('user');
  const [showError, setShowError] = React.useState(null);
  const [checkEmptyFields, setCheckEmptyFields] = React.useState(false);
  const [addPersonAttemptFailed, setAddPersonAttemptFailed] = React.useState(false);
  const [addPersonAttemptSuccess, setAddPersonAttemptSuccess] = React.useState(false);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const username = formData.get("username");
    const email = formData.get("email");
    const firstName = formData.get("firstName");
    const lastName = formData.get("lastName");
    const employeenumber = formData.get("employeeNumber");

    if (username !== "" && email !== "" && firstName !== "" && lastName !== "") {
      setCheckEmptyFields(false);
      try {
        const response = await AddPerson({
          "username": username,
          "email": email,
          "firstname": firstName,
          "lastname": lastName,
          "employeenumber": employeenumber,
          "role": role
        });
        if (response.status === 200) {
          setAddPersonAttemptFailed(false);
          setCheckEmptyFields(false);
          getAllUser();
          setOpen(false);
        } else {
          throw new Error(`Response status: ${response}`);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          setShowError(error.response.data.message);
        } else {
          setShowError(error?.message);
        }
        setAddPersonAttemptFailed(true);
      }
    } else {
      setCheckEmptyFields(true);
      setAddPersonAttemptFailed(false);
    }

  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={editStyle}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">
            <Box
              sx={{ fontWeight: 600, w: 100 }}
              style={{
                color: ThemeToggle === "dark" ? "narrowWhite" : "dark",
              }}
            >
              Add User
            </Box>
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
              setCheckEmptyFields(false);
              setAddPersonAttemptFailed(false)
              setAddPersonAttemptSuccess(false)
            }}
          />
        </Box>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Box sx={{ display: "flex" }}>
            <TextField
              margin="normal"
              fullWidth
              id="First Name"
              label="First Name"
              required
              type="text"
              name="firstName"
              autoComplete="new-password"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="Last Name"
              label="Last Name"
              required
              name="lastName"
              autoComplete="new-password"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
              sx={{ ml: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="new-password"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-Mail Adresse"
              autoComplete="new-password"
              name="email"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
              sx={{ ml: 2 }}
            />
          </Box>

          <Box sx={{ display: "flex" }}>
            <TextField
              margin="normal"
              fullWidth
              name="employeeNumber"
              label="Employee Number"
              type="Employee Number"
              id="Employee Number"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
            />
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={role}
              label="Role"
              sx={{ ml: 2, mt: 2, height: 60 }}
              onChange={handleChange}
            >
              <MenuItem value={'user'}>User</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
            </Select>
          </Box>
          <Collapse in={addPersonAttemptFailed}>
            <Alert severity="error" variant="filled">
              {showError}
            </Alert>
          </Collapse>
          <Collapse in={checkEmptyFields}>
            <Alert severity="warning" variant="filled">
              Alle Felder sind Pflichtfelder
            </Alert>
          </Collapse>

          <Box sx={{ display: "flex" }}>
            <Button
              endIcon={<CloseIcon />}
              fullWidth
              color={"transparent"}
              sx={{ mt: 3, mb: 2, border: 1 }}
              variant="contained"
              style={{
                height: "50px",
                color: ThemeToggle === "dark" ? "white" : "blue",
              }}
              onClick={() => {
                setOpen(false);
                setCheckEmptyFields(false);
                setAddPersonAttemptFailed(false)
                setAddPersonAttemptSuccess(false)
              }}
            >
              Cancel
            </Button>
            <Button
              color={"blue"}
              sx={{ mt: 3, mb: 2, ml: 2 }}
              endIcon={<SendIcon />}
              type="submit"
              fullWidth
              variant="contained"
              style={{ height: "50px", color: "white" }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddPersonModel;
