import React from "react";
import SideStyle from "../../Style/AddTime";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AddLogo } from "../../../../Images/CommonLogo";
import ConditionalDatePicker from "../../../../Component/ConditionalDatePicker";

export default function TimeAndDateSelector(props) {
  const { ThemeToggle, addBreakTime, checkNext, setEndTime, setEndDate, setStartTime, setStartDate, setEndBreak, setEndBreakDate, setStartBreakDate, setStartBreak, startTime, endTime, startBreak, endBreak, startDate, endDate, todayDate, startBreakDate, endBreakDate, minDate } = props;

  const TimePickerProp = {
    borderColor: "black",
    borderRadius: "10px",
    backgroundColor: "white",
    input: {
      color: "black",
      "&::placeholder": {
        opacity: 1,
      },
    },
    svg: { color: "black" }
  }

  return (
    <>
      {checkNext && (
        <>
          <label
            htmlFor=""
            className={
              "ml-2 " +
              (ThemeToggle === "dark"
                ? "text-lightdark"
                : "text-black font-bold")
            }
          >
            {"Pause von:"}
          </label>
          <div className="flex w-[100%] justify-start items-start text-lightdark">
            <div className="flex justify-start ">
              <div className="flex ">
                <div className={"text-white"}>
                  <ConditionalDatePicker
                    date={startBreakDate}
                    setDate={setStartBreakDate}
                    restrictStart={startDate}
                    restrictEnd={endDate}
                  />
                </div>
                <p
                  className={
                    SideStyle.p1 +
                    (ThemeToggle === "dark" ? "text-lightdark" : "text-black")
                  }
                >
                  {startBreakDate.toDateString() !== todayDate.toDateString()
                    ? startBreakDate.getDate() +
                    "." +
                    (startBreakDate.getMonth() + 1) +
                    "." +
                    startBreakDate.getFullYear()
                    : "heute"}
                </p>
              </div>

              <div className="w-[180px] ml-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={[""]}>
                    <DemoItem>
                      <TimePicker
                        views={["hours", "minutes"]}
                        value={startBreak}
                        ampm={false}
                        onChange={(e) => {
                          const date = new Date(e);
                          setStartBreak(e);
                          setStartBreakDate(
                            new Date(
                              startBreakDate.getFullYear(),
                              startBreakDate.getMonth(),
                              startBreakDate.getDate(),
                              date.getHours() | 0,
                              date.getMinutes() | 0
                            )
                          );
                        }}
                        sx={TimePickerProp}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <label
            htmlFor=""
            className={
              "ml-2 " +
              (ThemeToggle === "dark"
                ? "text-lightdark"
                : "text-black font-bold")
            }
          >
            {"Pause bis:"}
          </label>
          <div className="flex justify-start items-start text-lightdark">
            <div className="flex">
              <div className="flex pt-4">
                <div className="text-white">
                  <ConditionalDatePicker
                    date={endBreakDate}
                    setDate={setEndBreakDate}
                    restrictStart={startBreakDate}
                    restrictEnd={endDate}
                  />
                </div>
                <p
                  className={
                    SideStyle.p1 +
                    (ThemeToggle === "dark" ? "text-lightdark" : "text-black")
                  }
                >
                  {endBreakDate.toDateString() !== todayDate.toDateString()
                    ? endBreakDate.getDate() +
                    "." +
                    (endBreakDate.getMonth() + 1) +
                    "." +
                    endBreakDate.getFullYear()
                    : "heute"}
                </p>
              </div>
              <div className="w-[180px] ml-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={[""]}>
                    <DemoItem>
                      <TimePicker
                        views={["hours", "minutes"]}
                        value={endBreak}
                        ampm={false}
                        onChange={(e) => {
                          const date = new Date(e);
                          setEndBreak(e);
                          setEndBreakDate(
                            new Date(
                              endBreakDate.getFullYear(),
                              endBreakDate.getMonth(),
                              endBreakDate.getDate(),
                              date.getHours() | 0,
                              date.getMinutes() | 0
                            )
                          );
                        }}
                        sx={TimePickerProp}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div
                className="cursor-pointer pt-4 mr-2"
                onClick={() => {
                  addBreakTime();
                }}
              >
                <AddLogo />
              </div>
            </div>
          </div>
        </>
      )}
      {!checkNext && (
        <>
          <label
            htmlFor=""
            className={
              "mt-[6%] ml-2 " +
              (ThemeToggle === "dark"
                ? "text-lightdark"
                : "text-black font-bold")
            }
          >
            {"Gearbeitet von:"}
          </label>
          <div className="flex justify-start items-center text-lightdark">
            {!checkNext && (
              <div className="flex">
                <div className={"text-white"}>
                  <ConditionalDatePicker
                    date={startDate}
                    setDate={setStartDate}
                    restrictStart={minDate}
                    restrictEnd={new Date()}
                  />
                </div>
                <p
                  className={
                    SideStyle.p1 +
                    (ThemeToggle === "dark" ? " text-white" : " text-black")
                  }
                >
                  {startDate?.toDateString() !== todayDate.toDateString()
                    ? startDate.getDate() +
                    "." +
                    (startDate.getMonth() + 1) +
                    "." +
                    startDate.getFullYear()
                    : "heute"}
                </p>
              </div>
            )}
            <div className="w-[180px] ml-4 ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={[""]}>
                  <DemoItem>
                    <TimePicker
                      views={["hours", "minutes"]}
                      value={startTime}
                      ampm={false}
                      onChange={(e) => {
                        const date = new Date(e);
                        setStartTime(e);
                        setStartDate(
                          new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate(),
                            date.getHours() | 0,
                            date.getMinutes() | 0
                          )
                        );
                      }}
                      sx={TimePickerProp}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <label
            htmlFor=""
            className={
              "mt-[6%] ml-2" +
              (ThemeToggle === "dark"
                ? " text-lightdark"
                : " text-black font-bold")
            }
          >
            {"Gearbeitet bis:"}
          </label>
          <div className="flex justify-start items-center text-lightdark">
            {!checkNext && (
              <div className="flex ">
                <div className="text-white">
                  <ConditionalDatePicker
                    date={endDate}
                    setDate={setEndDate}
                    restrictStart={startDate}
                    restrictEnd={new Date()}
                  />
                </div>
                <p
                  className={
                    SideStyle.p1 +
                    (ThemeToggle === "dark"
                      ? " text-white"
                      : " text-black text-bold")
                  }
                >
                  {endDate.getDate() !== todayDate.getDate()
                    ? endDate.getDate() +
                    "." +
                    (endDate.getMonth() + 1) +
                    "." +
                    endDate.getFullYear()
                    : "heute"}
                </p>
              </div>
            )}
            <div className="w-[180px] ml-4 ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={[""]}>
                  <DemoItem>
                    <TimePicker
                      views={["hours", "minutes"]}
                      value={endTime}
                      ampm={false}
                      onChange={(e) => {
                        var date = new Date(e);
                        setEndTime(e);
                        setEndDate(
                          new Date(
                            endDate.getFullYear(),
                            endDate.getMonth(),
                            endDate.getDate(),
                            date.getHours() | 0,
                            date.getMinutes() | 0
                          )
                        );
                      }}
                      sx={TimePickerProp}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
}