import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context for storing user data
const UserContext = createContext();

// Create a provider component to provide user data to child components
export const UserProvider = ({ children }) => {
  // Check local storage for existing user data
  const existingUser = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(existingUser || null);

  // Update local storage when user state changes
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to access user data
export const useUser = () => useContext(UserContext);
