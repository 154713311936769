import * as React from "react";
import SideStyle from "../../Style/AddTime";
import WorkingHours from "../../../../Images/WorkingHours";
import CoffeeLogo from "../../../../Images/CoffeeLogo";

export default function WorkAndBreakTimes(props) {
    const { checkNext, startDate, tempTime, endDate, ThemeToggle, breakTimes, formatTime } = props;
    return (
        <>
            <div className={SideStyle.div2}>
                {checkNext && (
                    <>
                        <div className="flex justify-center">
                            <div className="mt-4 mr-2 pl-2">
                                <WorkingHours />
                            </div>
                            <label
                                className={
                                    SideStyle.label1 +
                                    "w-fit flex ml-auto mb-2 pr-2" +
                                    (ThemeToggle === "dark" ? " text-white" : "")
                                }
                            >
                                {startDate.getDate() +
                                    "." +
                                    (startDate.getMonth() + 1) +
                                    "." +
                                    startDate.getFullYear().toString().substr(-2) +
                                    "  " +
                                    tempTime.startTime +
                                    " - " +
                                    endDate.getDate() +
                                    "." +
                                    (endDate.getMonth() + 1) +
                                    "." +
                                    endDate.getFullYear().toString().substr(-2) +
                                    "  " +
                                    tempTime.endTime}
                            </label>
                        </div>
                    </>
                )}
            </div>
            {checkNext && breakTimes.length !== 0 ? (
                <>
                    {breakTimes.map((breakTime, index) => (
                        <div
                            className={
                                SideStyle.div2 +
                                (ThemeToggle === "dark" ? "text-white" : "text-black")
                            }
                            key={index}
                        >
                            <div className="flex justify-center">
                                <div className="mt-4 mr-2 pl-2">
                                    <CoffeeLogo />
                                </div>
                                <label
                                    className={
                                        SideStyle.label1 + " w-fit flex ml-auto mb-2 pr-2 "
                                    }
                                >
                                    {`${breakTime.start_date.getDate()}.${breakTime.start_date.getMonth() + 1
                                        }.${breakTime.start_date
                                            .getFullYear()
                                            .toString()
                                            .substr(-2)} ${formatTime(
                                                breakTime.start_date
                                            )} - ${breakTime.end_date.getDate()}.${breakTime.end_date.getMonth() + 1
                                        }.${breakTime.end_date
                                            .getFullYear()
                                            .toString()
                                            .substr(-2)} ${formatTime(breakTime.end_date)}`}
                                </label>
                            </div>
                        </div>
                    ))}
                </>
            ) : null}
        </>
    );
}
