import * as React from "react";
import SideStyle from "../../Style/AddTime";
import { Alert, Collapse } from "@mui/material";
import { RightArrow, SubmitLogo, Tick } from "../../../../Images/CommonLogo";

export default function SubmitDataButton(props) {
    const { errorModal, checkNext, resetData, setBreakTimes, moveForward, submitData, successModal, checkSubmision, PostData, setCheckSubmision, setCheckNext, errorMessage, theme } = props;
    return (
        <>
            <div className={SideStyle.div3}>
                {!checkNext && (
                    <>
                        <button
                            className={SideStyle.btn2}
                            onClick={() => {
                                resetData();
                            }}
                        >
                            {<p>Abbrechen</p>}
                        </button>
                        <button
                            className={
                                SideStyle.btn1 +
                                "" +
                                (moveForward ? " bg-red" : " bg-dullbrown")
                            }
                            onClick={() => {
                                if (moveForward) {
                                    setCheckNext(!checkNext);
                                }
                            }}
                        >
                            <>
                                <p className="flex">Pause</p>
                                <span className="ml-2">
                                    <RightArrow />
                                </span>
                            </>
                        </button>
                    </>
                )}
                {checkNext && (
                    <>
                        <button
                            className={SideStyle.btn2}
                            onClick={() => {
                                setCheckNext(false);
                                setBreakTimes([]);
                            }}
                        >
                            {<p>Zurück</p>}
                        </button>
                        <button
                            className={
                                SideStyle.btn3 +
                                ((submitData || successModal) && !errorModal
                                    ? " bg-green"
                                    : !submitData && errorModal
                                        ? " bg-red"
                                        : " bg-dullBlack")
                            }
                            onClick={() => {
                                if (submitData && checkSubmision) {
                                    PostData();
                                    setCheckSubmision(false);
                                } else {
                                    //If data is invalid and user press on submit data this will work
                                }
                            }}
                        >
                            <>
                                {!successModal && !errorModal && (
                                    <>
                                        <p className={SideStyle.p}>Abschicken</p>
                                        <span className="ml-2 ">
                                            <SubmitLogo />
                                        </span>
                                    </>
                                )}
                                {successModal && !errorModal && (
                                    <>
                                        <p className={SideStyle.p}>Fertig</p>
                                        <span className="ml-2 ">
                                            <Tick />
                                        </span>
                                    </>
                                )}
                                {errorModal && (
                                    <>
                                        <p className={SideStyle.p}>Error</p>
                                        <span className="ml-2 "></span>
                                    </>
                                )}
                            </>
                        </button>
                    </>
                )}
            </div>
            <Collapse in={errorModal}>
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{
                        width: "330px",
                        color: "black",
                        backgroundColor: theme.palette.dullPink.main
                    }}
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </>
    );
}
