import { Box, Drawer } from "@mui/material";
import React from "react";
import SidebarDrawerItems from "./SidebarDrawerItems";
import { logoutUser } from "../../../utils/axiosInstance";
import MenuItems from "../../../utils/MenuItems";

export function SidebarDrawer({
    drawerWidth,
    container,
    mobileOpen,
    handleDrawerTransitionEnd,
    handleDrawerClose,
    setCurrentComponent,
    setIsClosing,
    setMobileOpen,
    ListIcons,
    ListMenu
}) {
    const onItemClick = (text) => {
        setIsClosing(true);
        setMobileOpen(false);
        switch (text) {
            case MenuItems.PERSONEN:
                setCurrentComponent(0);
                break;
            case MenuItems.DETAIL1:
                setCurrentComponent(3);
                break;
            case MenuItems.DETAIL2:
                setCurrentComponent(4);
                break;
            case MenuItems.VERTRAG:
                setCurrentComponent(1);
                break;
            case MenuItems.AUSLOGGEN:
                const logout = logoutUser();
                logout
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                localStorage.removeItem("user");
                localStorage.removeItem("jwt");
                // eslint-disable-next-line no-restricted-globals
                location?.reload();
                break;
            default:
                console.log("Functionality not yet added");
        }
    };
    return (
        <Box component="nav" sx={{
            width: {
                sm: drawerWidth - 10
            },
            flexShrink: {
                sm: 0
            }
        }} aria-label="mailbox folders">

            <Drawer container={container} variant="temporary" open={mobileOpen} onTransitionEnd={handleDrawerTransitionEnd} onClose={handleDrawerClose} ModalProps={{
                keepMounted: true // Better open performance on mobile.

            }} sx={{
                display: {
                    xs: "block",
                    sm: "none"
                },
                // Open on mobile else close.
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth
                }
            }} PaperProps={{
                sx: {
                    backgroundColor: "secondary.dark",
                    color: "common.dark"
                }
            }}>
                <SidebarDrawerItems onItemClick={onItemClick} ListIcons={ListIcons} ListMenu={ListMenu} />
            </Drawer>
            <Drawer variant="permanent" sx={{
                display: {
                    xs: "none",
                    sm: "block"
                },
                // Open on large screen else close
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth
                },
                backgroundColor: "secondary.light"
            }} PaperProps={{
                sx: {
                    backgroundColor: "secondary.dark",
                    color: "common.dark"
                }
            }} open>
                <SidebarDrawerItems onItemClick={onItemClick} ListIcons={ListIcons} ListMenu={ListMenu} />
            </Drawer>
        </Box>
    )
}
