import React from "react";
import { ToggleTheme } from "../context/ToggleThemeProvider";

function CoffeeLogo() {
  var { ThemeToggle } = ToggleTheme();
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 23.75H25V26.25H5V23.75ZM25 3.75H5V16.25C5 19.0125 7.2375 21.25 10 21.25H17.5C20.2625 21.25 22.5 19.0125 22.5 16.25V12.5H25C26.3875 12.5 27.5 11.375 27.5 10V6.25C27.5 4.8625 26.3875 3.75 25 3.75ZM20 16.25C20 17.625 18.875 18.75 17.5 18.75H10C8.625 18.75 7.5 17.625 7.5 16.25V6.25H20V16.25ZM25 10H22.5V6.25H25V10Z"
        fill={ThemeToggle === "dark" ? "#ffffff" : "#000000"}
      />
    </svg>
  );
}

export default CoffeeLogo;
