import { api } from "../utils/axiosInstance";
import { getAuthHeaders } from "../utils/axiosInstance";

const AddPerson = async (data) => {
    const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/users`,
        data,
        getAuthHeaders()
    );
    return response;
};


const GetAllPerson = async (data) => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/persons`,
        getAuthHeaders()
    );
    return response.data;
};

export {
    AddPerson,
    GetAllPerson
};