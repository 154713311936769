import React, { createContext, useContext, useEffect, useState } from "react";
import darkTheme from "../Themes/darkTheme.js";
import whiteTheme from "../Themes/whiteTheme.js";
import { ToggleTheme } from "./ToggleThemeProvider.js";

// Create a context for storing theme
const ThemeContext = createContext();

// Create a provider component to provide theme to child components
export const UseThemeProvider = ({ children }) => {
  const { ThemeToggle } = ToggleTheme();

  var [currentTheme, setCurrentTheme] = useState(
    ThemeToggle === "dark" ? darkTheme : whiteTheme
  );

  //changing the theme based on toggle
  useEffect(() => {
    setCurrentTheme(ThemeToggle === "dark" ? darkTheme : whiteTheme);
  }, [ThemeToggle]);

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to access theme
export const useTheme = () => useContext(ThemeContext);
