import * as React from "react";
import UserTable from "./UserModal/UserTable";
import AddTime from "./AddTime";
import Style from "../Style/UserDetail";
import { ToggleTheme } from "../../../context/ToggleThemeProvider";
import { FetchTimes } from "../../../Api/AddTimerApi";

export default function UserDetail(props) {

  const { currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, minDate, setMinDate, detailModalOpen, setDetailModalOpen } = props
  const [checkNext, setCheckNext] = React.useState(false);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [startBreak, setStartBreak] = React.useState(null);
  const [endBreak, setEndBreak] = React.useState(null);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [todayDate, setTodayStartDate] = React.useState(new Date());
  const [moveForward, setMoveForward] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [submitData, setSubmitData] = React.useState(true);
  const [startBreakDate, setStartBreakDate] = React.useState(new Date());
  const [endBreakDate, setEndBreakDate] = React.useState(new Date());
  const [breakTimes, setBreakTimes] = React.useState([]);
  const [detailTime, setDetailTime] = React.useState([]);

  const [tempTime, setTempTime] = React.useState({
    startTime: "",
    endTime: "",
    startBreak: "",
    endBreak: "",
  });

  const [userTimes, setUserTimes] = React.useState();
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [downloadItems, setDownloadItems] = React.useState([]);
  var jwtToken = localStorage.getItem("jwt");

  const AddTimeProps = {
    checkNext, startTime, endTime, startBreak, endBreak, setStartTime, setStartBreak, setEndBreak, setEndTime, setCheckNext, startDate, setStartDate,
    endDate, setEndDate, todayDate, setTodayStartDate, moveForward, setMoveForward, tempTime, setTempTime, startBreakDate, setStartBreakDate, endBreakDate,
    breakTimes, setEndBreakDate, setBreakTimes, successModal, setSuccessModal, errorModal, setErrorModal, submitData, setSubmitData, minDate, setMinDate
  };

  const userTableProps = {
    filteredItems, setFilteredItems, downloadItems, setDownloadItems, currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate,
    setCurrentDate, detailTime, setDetailTime, setMinDate, minDate, detailModalOpen, setDetailModalOpen, convertSecondsToTimeFormat
  }

  React.useEffect(() => {

    //Parameters to send in get request for whole month
    var params = `/api/me/times?from=${currentYear}-${currentMonth + 1
      }-01&to=${currentYear}-${currentMonth + 2}-01&mode=sum`;

    var userRecord = FetchTimes(params);
    userRecord
      .then((data) => {
        if (data) {
          setUserTimes(data);
        }
      })
      .catch((error) => {
      });

    //Parameters to send in get request for whole day
    var params2 = `/api/me/times?from=${currentYear}-${currentMonth + 1
      }-01&to=${currentYear}-${currentMonth + 2}-01&mode=perday`;

    var userRecord1 = FetchTimes(params2);
    userRecord1
      .then((data) => {
        if (data) {
          setDetailTime(data);
        }
      })
      .catch((error) => {
      });
  }, [successModal, jwtToken, currentYear, currentMonth]);

  function convertSecondsToTimeFormat(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  React.useEffect(() => {
    if (userTimes) {
      setFilteredItems(
        Object.keys(userTimes)?.map((key) => {
          const entry = userTimes[key];
          return {
            id: key,
            date: key,
            time: convertSecondsToTimeFormat(entry.total),
          };
        })
      );
      setDownloadItems(
        Object.keys(userTimes)?.map((key) => {
          const entry = userTimes[key];
          return {
            Datum: key,
            Dauer: convertSecondsToTimeFormat(entry.total),
          };
        })
      );
    }
  }, [userTimes]);

  var { ThemeToggle } = ToggleTheme();

  return (
    <>

      <div className={Style.div4 + (ThemeToggle === "dark" ? "bg-browndark" : "")}>
        <AddTime {...AddTimeProps} />
      </div>
      <div className={Style.div5}>
        <div className="lg:w-[60%] w-[100%]">
          <UserTable
            {...userTableProps}
          />
        </div>
        <div className={Style.div6}>
          <AddTime {...AddTimeProps} />
        </div>
      </div>
    </>
  );
}
