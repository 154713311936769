import * as React from "react";
import SideStyle from "../Style/AddTime";
import { PostWorkOrBreakTime } from "../../../Api/AddTimerApi";
import { ToggleTheme } from "../../../context/ToggleThemeProvider";
import { useTheme } from "../../../context/UseThemeProvider";
import SubmitDataButton from "./AddTimerComponent/SubmitDataButton";
import TimeAndDateSelector from "./AddTimerComponent/TimeAndDateSelector";
import WorkAndBreakTimes from "./AddTimerComponent/WorkAndBreakTimes";
import checkFieldValidations from "../../../utils/IsNull";

export default function AddTime(props) {
  const [checkSubmision, setCheckSubmision] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  var { ThemeToggle } = ToggleTheme();
  var { currentTheme: theme } = useTheme();

  const {
    checkNext, startTime, endTime, startBreak, endBreak, startDate, endDate, todayDate, moveForward, successModal,
    errorModal, tempTime, submitData, startBreakDate, endBreakDate, breakTimes, minDate, setSubmitData, setStartBreakDate,
    setEndBreakDate, setMoveForward, setTempTime, setBreakTimes, setStartBreak, setEndBreak, setSuccessModal, setErrorModal,
    setStartDate, setEndDate, setStartTime, setEndTime, setCheckNext
  } = props;

  const formatTime = (time) => {
    const date = new Date(time);
    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const addBreakTime = () => {
    if (
      submitData &&
      !checkFieldValidations(startBreak?.$H) &&
      !checkFieldValidations(startBreak?.$M) &&
      !checkFieldValidations(endBreak?.$H) &&
      !checkFieldValidations(endBreak?.$M)
    ) {
      const newBreakTime = {
        start_date: startBreakDate,
        end_date: endBreakDate,
      };
      setBreakTimes((prevBreakTimes) => [...prevBreakTimes, newBreakTime]);
      setStartBreakDate(startDate);
      setEndBreakDate(endDate);
      setStartBreak(null);
      setEndBreak(null);
    }
  };

  const WorkAndBreakTimesProps = {
    checkNext, startDate, tempTime, endDate, ThemeToggle, breakTimes, formatTime
  }

  const TimeAndDateSelectorProps = {
    checkNext, setEndTime, setEndDate, setStartTime, setStartDate, setEndBreak, setEndBreakDate,
    setStartBreakDate, setStartBreak, startTime, endTime, startBreak, endBreak, startDate, endDate,
    todayDate, startBreakDate, endBreakDate, minDate, ThemeToggle, addBreakTime
  }

  const SubmitDataButtonProps = {
    resetData, errorModal, checkNext, checkSubmision, submitData,
    successModal, PostData, setCheckNext, moveForward, setCheckSubmision, errorMessage, setBreakTimes, theme
  }


  function checkWorkingHours() {
    try {
      return startDate < endDate;
    } catch {
      return false;
    }
  }

  function isTimeRangeOverlapping(start1, end1, start2, end2) {
    return start1 < end2 && end1 > start2;
  }

  function checkNoPauseCollapse() {
    for (var i = 0; i < breakTimes.length; i++) {
      var breakStart = breakTimes[i].start_date;
      var breakEnd = breakTimes[i].end_date;
      if (
        isTimeRangeOverlapping(
          breakStart,
          breakEnd,
          startBreakDate,
          endBreakDate
        )
      ) {
        return false;
      }
    }
    return true;
  }

  function checkBreakHours() {
    if (!startBreak && !endBreak) {
      setSubmitData(true);
    } else if (
      startBreak?.$H !== undefined &&
      startBreak?.$M !== undefined &&
      endBreak?.$H !== undefined &&
      endBreak?.$M !== undefined
    ) {
      if (
        startBreakDate < endBreakDate &&
        startDate < startBreakDate &&
        endDate > endBreakDate &&
        checkNoPauseCollapse()
      ) {
        setSubmitData(true);
      } else {
        setSubmitData(false);
      }
    } else {
      setSubmitData(false);
    }
  }

  React.useEffect(() => {
    if (startDate) {
      setStartBreakDate(startDate);
    }
    if (endDate) {
      setEndBreakDate(endDate);
    }
    if (
      startTime && endTime &&
      !checkFieldValidations(startTime?.$H) &&
      !checkFieldValidations(startTime?.$M) &&
      !checkFieldValidations(endTime?.$H) &&
      !checkFieldValidations(endTime?.$M)
    ) {
      const formattedStartTime = formatTime(startTime);
      const formattedEndTime = formatTime(endTime);

      if (checkWorkingHours()) {
        setTempTime({
          ...tempTime,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
        });
        setMoveForward(true);
      } else {
        setMoveForward(false);
      }
    } else {
      setMoveForward(false);
    }
  }, [startTime, endTime, startDate, endDate]);

  React.useEffect(() => {
    checkBreakHours();
  }, [startBreak, endBreak, startBreakDate, endBreakDate]);

  function formatSentData(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      formatTime(date)
    );
  }

  function PostData() {
    var startFormat = formatSentData(startDate);
    var endFormat = formatSentData(endDate);
    var tempbreak = breakTimes.map((data) => ({
      start: formatSentData(data.start_date),
      end: formatSentData(data.end_date),
    }));
    if (
      submitData &&
      startBreak?.$H !== undefined &&
      startBreak?.$M !== undefined &&
      endBreak?.$H !== undefined &&
      endBreak?.$M !== undefined
    ) {
      tempbreak.push({
        start: formatSentData(startBreakDate),
        end: formatSentData(endBreakDate),
      });
    }

    var data = {
      work: [
        {
          start: startFormat,
          end: endFormat,
        },
      ],
      break: tempbreak,
    };

    var response = PostWorkOrBreakTime(data);
    response
      .then((res) => {
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
          setMoveForward(false);
          setCheckSubmision(true);
          resetData();
          setSubmitData(true);
        }, 2000);
      })
      .catch((error) => {
        setErrorModal(true);
        setErrorMessage(error?.response?.data?.message);
        setTimeout(() => {
          setErrorModal(false);
          setCheckSubmision(true);
          setSubmitData(true);
        }, 2000);
      });
  }

  function resetData() {
    setStartDate(new Date());
    setEndDate(new Date());
    setStartBreakDate(startDate);
    setEndBreakDate(endDate);
    setStartBreak(null);
    setEndBreak(null);
    setStartTime(null);
    setEndTime(null);
    setCheckNext(false);
    setSubmitData(true);
    setBreakTimes([]);
  }

  return (
    <>
      <div
        className={
          SideStyle.mainDiv + (ThemeToggle === "dark" ? " bg-browndark" : "")
        }
      >
        <WorkAndBreakTimes
          {...WorkAndBreakTimesProps}
        />

        <TimeAndDateSelector
          {...TimeAndDateSelectorProps}
        />
        <SubmitDataButton
          {...SubmitDataButtonProps}
        />
      </div>
    </>
  );
}
