import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import MenuItems from '../utils/MenuItems';

export default function BreadCrumb(props) {
    const { currentComponent, setCurrentComponent, userVertragName } = props

    var setHeaderTitle = (
        <>
            {parseInt(currentComponent + "") === 0 && MenuItems.PERSONEN}
            {parseInt(currentComponent + "") === 1 && MenuItems.VERTRAG}
            {parseInt(currentComponent + "") === 3 && MenuItems.DETAIL1}
            {parseInt(currentComponent + "") === 4 && MenuItems.DETAIL2}
        </>
    );

    const breadcrumbs = [
        <Link underline="none" key="1" color="white" className='cursor-pointer' >
            {setHeaderTitle}
        </Link>
    ];

    const vertragCrumbs = [
        <Link underline="none" key="1" color="white" className='cursor-pointer' onClick={() => { setCurrentComponent(0) }}>
            Personen
        </Link>,
        <Link underline="none" key="1" color="white" className='cursor-pointer' onClick={() => { setCurrentComponent(0) }}>
            {userVertragName}
        </Link>
    ];

    return (
        <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb" fontSize="large">
                {parseInt(currentComponent + "") === 1 && vertragCrumbs}
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}
