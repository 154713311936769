import axios from "axios";

const getLogin = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, data);
    return response;
};

const passwordReset = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, data);
    return response;
};

const confirmResetPassword = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/confirm-registration`, data);
    return response;
};


export {
    getLogin,
    passwordReset,
    confirmResetPassword
};