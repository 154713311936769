import { useEffect, useState } from "react";
import Style from "./Style/Style.js";
import DownloadIcon from '@mui/icons-material/Download';
import CsvDownloader from "../../Component/CsvDownloader.jsx";
import { ToggleTheme } from "../../context/ToggleThemeProvider.js";

function UserDetail2(props) {
    const { currentMonth, currentYear, months, } = props
    var { ThemeToggle } = ToggleTheme();
    var colStyle = Style.td + " md:pl-4 py-6";
    const [downloadItems, setDownloadItems] = useState(null);
    const [employeeData, setEmployeeData] = useState([]);

    useEffect(() => {
        setDownloadItems(
            employeeData.map(entry => ({
                Tag: entry['Tag'],
                Soll: entry['Soll'],
                Dienstzeit: entry['Dienstzeit'],
                'Arb-Std': entry['Arb-Std'],
                'Samstag 13:00- 21:00': entry['Samstag 13:00- 21:00'],
                'Sonntag 00:00- 24:00': entry['Sonntag 00:00- 24:00'],
                'Nacht 21:00- 06:00': entry['Nacht 21:00- 06:00'],
                'Auszahlung Mehra': entry['Auszahlung Mehra'],
                'Urlab/Tagen': entry['Urlab/Tagen'],
                'Krankheit/Tagen': entry['Krankheit/Tagen'],
                'KA in Std': entry['KA in Std']
            }))
        )
    }, [])


    return (
        <>
            <div className="h-fit">
                <button className="mt-8 md:mt-0" style={{ color: ThemeToggle === "dark" ? "white" : "black", padding: 5, fontWeight: 900 }} onClick={() => {
                    CsvDownloader(downloadItems, (months[currentMonth]) + " " + currentYear);
                }}> Download <DownloadIcon />
                </button>
                <table
                    className={Style.table + (ThemeToggle === "dark" ? " bg-gray" : "")}
                    style={{ height: "calc(100%-250px)]" }}
                >
                    <thead>
                        <tr>
                            <th className={Style.th}>Tag</th>
                            <th className={Style.th}>Soll</th>
                            <th className={Style.th + "pl-0"}>Dienstzeit</th>
                            <th className={Style.th}>Arb-Std</th>
                            <th className={Style.th}>Samstag 13:00- 21:00</th>
                            <th className={Style.th}>Sonntag 00:00- 24:00</th>
                            <th className={Style.th}>Nacht 21:00- 06:00</th>
                            <th className={Style.th}>Auszahlung Mehra</th>
                            <th className={Style.th}>Urlab/Tagen</th>
                            <th className={Style.th}>Krankheit/Tagen</th>
                            <th className={Style.th}>KA in Std</th>
                        </tr>
                    </thead>
                    <tbody>
                        {downloadItems?.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-narrow" : "bg-textBox"}>
                                <td className={Style.td + Style.tag}>
                                    {item['Tag']}
                                </td>
                                <td className={colStyle}>
                                    {item['Soll']}
                                </td>
                                <td className={colStyle}>
                                    {item['Dienstzeit']}
                                </td>
                                <td className={colStyle}>
                                    {item['Arb-Std']}
                                </td>
                                <td className={colStyle}>
                                    {item['Samstag 13:00- 21:00']}
                                </td>
                                <td className={colStyle}>
                                    {item['Sonntag 00:00- 24:00']}
                                </td>
                                <td className={colStyle}>
                                    {item['Nacht 21:00- 06:00']}
                                </td>
                                <td className={colStyle}>
                                    {item['Auszahlung Mehra']}
                                </td>
                                <td className={colStyle}>
                                    {item['Urlab/Tagen']}
                                </td>
                                <td className={colStyle}>
                                    {item['Krankheit/Tagen']}
                                </td>
                                <td className={colStyle}>
                                    {item['KA in Std']}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {
                    downloadItems?.length === 0 && (
                        <div className={Style.noRecord}>
                            No Record Found
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default UserDetail2;
