import * as React from "react";
import { ToggleTheme } from "../../../../context/ToggleThemeProvider";
import { useTheme } from "../../../../context/UseThemeProvider";
import { detailStyle } from "../../../Person/Style/PersonModelStyle";
import { Box, Modal, Typography } from "@mui/material";
import { Divider } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

export default function Detail({ setDetailModalOpen, detailModalOpen }) {

    var { ThemeToggle } = ToggleTheme();
    const { currentTheme: theme } = useTheme();
    const datePickerRef = React.useRef(null);

    const handleClickOutside = (event) => {
        if (
            datePickerRef.current &&
            !datePickerRef.current.contains(event.target)
        ) {
            setDetailModalOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    var textColor = (ThemeToggle === "dark" ? "white" : "narrowWhite");

    return (
        <>
            <div >
                <Modal
                    open={detailModalOpen}
                    onClose={() => setDetailModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={detailStyle}
                        ref={datePickerRef}
                        style={{
                            zIndex: 1,
                            overflow: "scroll",
                            backgroundColor: (ThemeToggle === "dark" ? theme.palette.secondary.dark : "")
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="h4">
                                <Box
                                    sx={{ fontWeight: 600, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Aktuelle Übersicht
                                </Box>
                            </Typography>
                            <Box sx={{ mt: 1 }}>
                                <CloseIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setDetailModalOpen(false);
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Übertrag vom Vormonat:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    12,34 Std
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Soll laut Vertrag:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    80,00 Std
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Istarbeitszeit:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    0,00 Std
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Monatssaldo:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    0,00 Std
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    übertrag Folgemonat:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    0,00 Std
                                </Box>
                            </Typography>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Urlaubsanspruch:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    26
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Resturl. (Vorjahr):
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    10
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Beantragt/Gewährt:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    10
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    Resturlaub:
                                </Box>
                            </Typography>
                            <Typography variant="p">
                                <Box
                                    sx={{ fontWeight: 400, w: 100 }}
                                    style={{
                                        color: textColor
                                    }}
                                >
                                    10
                                </Box>
                            </Typography>
                        </Box>
                    </Box>
                </Modal >
            </div>

        </>
    );
}

